import { LOGOUT_SESSION, USER_CART_DETAILS } from "../constants/Constants";

let initialState = {
  cart: [],
};

const userCartDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case USER_CART_DETAILS:
      return {
        cart: action.payload,
      };

      case LOGOUT_SESSION:
        return { ...initialState };

    default:
      break;
  }

  return state;
};

export default userCartDetailsReducer;
