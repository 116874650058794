import axios from "axios";
import { RESTAURANT_ID, DEV_URL } from "../redux/constants/Constants";

export const getRestaurantDetails = async () => {
  const url = `${DEV_URL}/restaurant-details/?type=user&restaurant_id=${RESTAURANT_ID}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const userRegistration = async (token, data) => {
  const url = `${DEV_URL}/user/`;

  console.log(token, data);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status > 400 &&
      error.response.status < 500
    ) {
      // Handle unauthorized access, for example, redirect to login page
      console.log(
        "Unauthorized access. Redirecting to login page.",
        error.response.data.message
      );
    } else if (error.response && error.response.status === 400) {
      console.log(error.response.data.message);
      return error.response
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
};

export const createCustomer = async (token, data, user_id) => {
  const url = `${DEV_URL}/customer/`;

  console.log(token, data, user_id);
  const userID = user_id.toString()
  console.log(userID)

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      // Handle unauthorized access, for example, redirect to login page
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
};

export const userLogin = async (token, data) => {
  const url = `${DEV_URL}/rest-auth/login/v1/`;

  console.log(token, data);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      // Handle unauthorized access, for example, redirect to login page
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
};

export const userForgotPassword = async (token, data) => {
  const url = `${DEV_URL}/rest-auth/password/reset/v1/`;

  console.log(token, data);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      // Handle unauthorized access, for example, redirect to login page
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      return error;
    }
  }
};

export const userGuestLogin = async (token, data) => {
  const url = `${DEV_URL}/send/code/`;

  console.log(token, data);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      // Handle unauthorized access, for example, redirect to login page
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
};

export const guestOtpVerification = async (token, data) => {
  const url = `${DEV_URL}/guest/verify/`;

  console.log(token, data);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };

  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      // Handle unauthorized access, for example, redirect to login page
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
};

export const getProfileDetails = async (token, customerId) => {
  const url = `${DEV_URL}/customer/?customer_id=${customerId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      // Handle unauthorized access, for example, redirect to login page
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
}

export const updateProfileDetails = async (token, customerId, data) => {
  const url = `${DEV_URL}/user/${customerId}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if(error.response && error.response.status >= 400 && error.response.status < 500) {
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
}

export const changePasswordAPI = async (token, data) => {
  const url = `${DEV_URL}/rest-auth/password/reset/confirm/v1/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if(error.response && error.response.status >= 400 && error.response.status < 500) {
      console.log("Unauthorized access. Redirecting to login page.", error.response);
      return error.response;
    } else {
      // Handle other types of errors
      console.error("Error during login:", error);
      throw error;
    }
  }
}

export const getUserShippingAddress = async (token, customer_id) => {
  const url = `${DEV_URL}/shipping/?customer_id=${customer_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addUserShippingAddress = async (token, data) => {
  const url = `${DEV_URL}/shipping/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateUserShippingAddress = async (token, shippingID, data) => {
  const url = `${DEV_URL}/shipping/${shippingID}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const getUserBllingAddress = async (token, customer_id) => {
  const url = `${DEV_URL}/billing/?customer_id=${customer_id}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const addUserBillingAddress = async (token, data) => {
  const url = `${DEV_URL}/billing/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.post(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const updateUserBillingAddress = async (token, billingID, data) => {
  const url = `${DEV_URL}/billing/${billingID}/`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
     },
  };
  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
};

