import axios from "axios";
import { RESTAURANT_ID, DEV_URL } from "../redux/constants/Constants";

export const getShippingMethod = async (token) => {
    const url = `${DEV_URL}/shipping-method/?status=ACTIVE&restaurant_id=${RESTAURANT_ID}`;
    console.log(url)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.log(error);
        throw error;
      }
    }
  }

  export const feeCalculation = async (token, data) => {
    const url = `${DEV_URL}/v2/fee-x/`;
    console.log(url)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const getPincode = async (token, pincode) => {
    const url = `${DEV_URL}/pincode_availability/?filter_codes=${pincode}`;
    console.log(pincode)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      console.log(response)
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const createOrderId = async (token, data) => {
    const url = `${DEV_URL}/payment/in/`;
    console.log(data, token)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const createOrderDetails = async (token, data) => {
    const url = `${DEV_URL}/order-detail-x/`;
    console.log(data, token)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const createPlacedOrder = async (token, data) => {
    const url = `${DEV_URL}/v2/payment/`;
    console.log(data, token)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  // Invoice

  export const saveInvoicePdf = async (token, formData, order_id) => {
    const url = `${DEV_URL}/order/${order_id}/`;
  
    const config = {
      headers: {
        "Content-Type": "tiparmult/form-data",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.patch(url, formData, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const getOrderList = async (token, customerId) => {
    const url = `${DEV_URL}/v2/customer-payment/?customer_id=${customerId}&restaurant_id=${RESTAURANT_ID}`;
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const getPaymentDetails = async (token, order_id) => {
    const url = `${DEV_URL}/order/${order_id}/`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const getOrderDetails = async (token, order_id) => {
    const url = `${DEV_URL}/order-items-x/?order_id=${order_id}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const getDeliveryOrder = async (token, order_id, customer_id) => {
    const url = `${DEV_URL}/create-delhivery-order/?order_id=${order_id}&customer_id=${customer_id}`;
    console.log(url)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch(error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const trackOrderDetails = async (token, waybill) => {
    const url = `${DEV_URL}/order_tracking/?waybill=${waybill}`;
    console.log(token, waybill);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const createCancelOrder = async (token, data) => {
    const url = `${DEV_URL}/cancel_order/`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.log(error);
        // throw error;
      }
    }
  };

  export const markProductUnavailable = async (token, order_item_id, data) => {
    const url = `${DEV_URL}/order-item-x/${order_item_id}/`;
    console.log(data)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.put(url, data, config);
      return response;
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  