import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ShippingPolicy() {
  const aboutUs = useSelector((state) => state.status.restaurant.about_us);
  const address = useSelector((state) => state.status.restaurant.address);
  const city = useSelector((state) => state.status.restaurant.city);
  const state = useSelector((state) => state.status.restaurant.state);
  const country = useSelector((state) => state.status.restaurant.country);
  const zip = useSelector((state) => state.status.restaurant.zip);
  const email = useSelector((state) => state.status.restaurant.support_email);
  const phone = useSelector(
    (state) => state.status.restaurant.support_phone_number
  );
  const working_hours = useSelector(
    (state) => state.status.restaurant.working_hours
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  console.log(newAddress);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <div>
      <div style={{ minHeight: "800px" }}>
        <ToastContainer
          position="top-center"
          className="mt-4 ToastContainer"
          limit={1}
        />

        <div className="about-us-wrap">
          <div
            style={{ fontWeight: 900 }}
            className="d-flex justify-content-center"
          >
            <br />
            <h2 className="mb-4">Shipping Policy</h2>
          </div>

          <p style={{ textAlign: "justify" }}>
            <h6
              style={{ fontWeight: "600", fontSize: "17px" }}
              className="my-2"
            >
              Welcome to Bamboosa Agro Innovations Private Limited!
            </h6>
            We are committed to delivering your orders in a timely and efficient
            manner. Please review our shipping policy below:
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              1. Shipping Locations
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>We currently ship to all locations within India.</li>
              <li>International shipping is not available at this time.</li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              2. Shipping Charges
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                <strong>Standard Shipping:</strong> A flat rate of ₹100 is
                charged for all orders below ₹3000.
              </li>
              <li>
                <strong>Free Shipping:</strong> Orders above ₹9000 qualify for
                free standard shipping.
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              3. Order Processing Time
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                Orders are processed within 1-2 business days (excluding
                weekends and holidays) after receiving your order confirmation
                email.
              </li>
              <li>
                You will receive another notification when your order has
                shipped.
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              4. Shipping Time
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                <strong>Standard Shipping:</strong> Delivery within 5-7 business
                days.
              </li>
              <li>
                <strong>Expedited Shipping:</strong> Delivery within 2-3
                business days (additional charges may apply).
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              5. Tracking Your Order
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                Once your order has shipped, you will receive an email with a
                tracking number and a link to track your order online.
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              6. Delivery Issues
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                If your order hasn’t arrived within the estimated delivery time,
                please contact our customer support at {email} / {phone}.
              </li>
              <li>
                In case of a delay, please allow an additional 2-3 business days
                before contacting us.
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              7. Shipping Address
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                Please ensure that the shipping address provided is accurate and
                complete. Bamboosa is not responsible for orders shipped to
                incorrect addresses provided by the customer.
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              8. Undeliverable Packages
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                If a package is returned to us due to an incorrect or incomplete
                address, we will contact you for an updated address and reship
                the package. Additional shipping charges may apply.
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              9. Lost or Stolen Packages
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                Bamboosa is not responsible for lost or stolen packages
                confirmed to be delivered to the address entered for an order.
                Upon inquiry, we will confirm the delivery to the address
                provided, date of delivery, tracking information, and shipping
                carrier information for the customer to investigate.
              </li>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "20px" }}
              className="mb-2 mt-4"
            >
              10. Contact Us
            </h6>
            <ul
              style={{ textAlign: "justify", paddingLeft: "50px" }}
              className="product-list-disc"
            >
              <li>
                For any questions or concerns regarding shipping, please contact
                us at:
              </li>
              <ul style={{ textAlign: "justify", paddingLeft: "50px" }}>
                <li style={{ listStyle: "circle" }}>
                  <strong>Email:</strong> {email}
                </li>
                <li style={{ listStyle: "circle" }}>
                  <strong>Phone:</strong> {phone}
                </li>
                <li style={{ listStyle: "circle" }}>
                  <strong>Customer Support Hours:</strong> Monday to Friday, 9
                  AM to 6 PM
                </li>
              </ul>
            </ul>
            <h6
              style={{ fontWeight: "600", fontSize: "15px" }}
              className="my-3"
            >
              <em>
                Thank you for shopping with Bamboosa! We appreciate your
                business and are dedicated to providing you with eco-friendly,
                high-quality bamboo clothing.
              </em>
            </h6>
          </p>
        </div>
      </div>
    </div>
  );
}
