import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  const aboutUs = useSelector((state) => state.status.restaurant.about_us);
  const address = useSelector((state) => state.status.restaurant.address);
  const city = useSelector((state) => state.status.restaurant.city);
  const state = useSelector((state) => state.status.restaurant.state);
  const country = useSelector((state) => state.status.restaurant.country);
  const zip = useSelector((state) => state.status.restaurant.zip);
  const email = useSelector((state) => state.status.restaurant.support_email);
  const phone = useSelector(
    (state) => state.status.restaurant.support_phone_number
  );
  const working_hours = useSelector(
    (state) => state.status.restaurant.working_hours
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  console.log(newAddress);

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
      />

      <div className="about-us-wrap">
        <div
          style={{ fontWeight: 900 }}
          className="d-flex justify-content-center"
        >
          <h2 className="mb-4">Terms and Conditions</h2>
        </div>
        <h6 style={{ fontWeight: "600", fontSize: "17px" }} className="my-2">
          Welcome to Bamboosa Agro Innovations(OPC) Private Limited!
        </h6>
        <p>
          Welcome to Bamboosa! These Terms and Conditions outline the rules and
          regulations for the use of our website and the purchase of our bamboo
          clothing products. By accessing or using our website and purchasing
          our products, you agree to abide by these Terms and Conditions. Please
          read them carefully before proceeding.
        </p>

        <br />
        <h5>1. Interpretation</h5>
        <p>
          "Bamboosa," "we," "our," or "us" refers to the company selling bamboo
          clothing products.
          <br />
          "You" refers to the user or customer accessing our website and
          purchasing our products.
        </p>

        <br />
        <h5>2. Products</h5>
        <p>
          Bamboosa specializes in the design, manufacturing, and sale of
          clothing made from sustainable bamboo fabric.
          <br />
          We strive to provide accurate product descriptions, images, and
          pricing information. However, we do not guarantee that such
          information is always complete, accurate, or up-to-date.
        </p>

        <br />
        <h5>3. Ordering and Payment</h5>
        <p>
          By placing an order through our website, you warrant that you are
          legally capable of entering into binding contracts.
          <br />
          All orders are subject to availability and acceptance. We reserve the
          right to refuse or cancel any order at our discretion.
          <br />
          Payment for orders must be made using the provided payment methods on
          our website. We use secure payment gateways to ensure the safety of
          your transactions.
        </p>

        <br />
        <h5>4. Shipping and Delivery</h5>
        <p>
          We aim to process and dispatch orders promptly. However, shipping
          times may vary depending on your location and other factors beyond our
          control.
          <br />
          Shipping fees, if applicable, will be calculated and displayed at
          checkout. Any additional customs duties or taxes incurred during
          shipping are the responsibility of the customer.
        </p>

        <br />
        <h5>5. Returns and Refunds</h5>
        <p>
          Please refer to our Return Policy for detailed information on returns,
          exchanges, and refunds.
          <br />
          We reserve the right to refuse returns or exchanges that do not comply
          with our return policy guidelines.
        </p>

        <br />
        <h5>6. Intellectual Property</h5>
        <p>
          All content on our website, including but not limited to text, images,
          logos, and trademarks, is the property of Bamboosa and is protected by
          intellectual property laws.
          <br />
          You may not use, reproduce, or distribute any content from our website
          without prior written permission from us.
        </p>

        <br />
        <h5>7. Privacy and Data Protection</h5>
        <p>
          We are committed to protecting your privacy and personal information.
          Please refer to our Privacy Policy for details on how we collect, use,
          and protect your data.
        </p>

        <br />
        <h5>8. Limitation of Liability</h5>
        <p>
          To the fullest extent permitted by law, Bamboosa shall not be liable
          for any direct, indirect, incidental, consequential, or punitive
          damages arising out of or in connection with your use of our website
          or the purchase of our products.
        </p>

        <br />
        <h5>9. Governing Law and Jurisdiction</h5>
        <p>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of [Your Country]. Any disputes arising under
          these terms shall be subject to the exclusive jurisdiction of the
          courts of [Your Jurisdiction].
        </p>

        <br />
        <h5>10. Amendments</h5>
        <p>
          Bamboosa reserves the right to update or modify these Terms and
          Conditions at any time without prior notice. Any changes will be
          effective immediately upon posting on our website.
        </p>

        <p>
          By using our website and purchasing our products, you acknowledge that
          you have read, understood, and agreed to be bound by these Terms and
          Conditions. If you do not agree with any part of these terms, please
          refrain from using our website or purchasing our products. If you have
          any questions or concerns, please contact us at{" "}
          <a href={`mailto:${email}`}>{email}</a>.
        </p>
      </div>
    </div>
  );
}
