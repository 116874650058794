import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const aboutUs = useSelector((state) => state.status.restaurant.about_us);
  const address = useSelector((state) => state.status.restaurant.address);
  const city = useSelector((state) => state.status.restaurant.city);
  const state = useSelector((state) => state.status.restaurant.state);
  const country = useSelector((state) => state.status.restaurant.country);
  const zip = useSelector((state) => state.status.restaurant.zip);
  const email = useSelector((state) => state.status.restaurant.support_email);
  const phone = useSelector(
    (state) => state.status.restaurant.support_phone_number
  );
  const working_hours = useSelector(
    (state) => state.status.restaurant.working_hours
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  console.log(newAddress);

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
      />

      {/* <!-- contact wrapper area start --> */}
      <div className="contact-area section-padding mx-5 my-2">
        <div className="container custom-container my-5">
          <div className="row d-flex justify-content-center">
            {/* <div className="col-lg-6">
                            <div className='card px-3 py-3 w-100 h-100'>
                                <div className="contact-message">
                                    <h2>tell us your project</h2>
                                    <form id="contact-form" action="#" method="post" className="contact-form">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <input name="first_name" placeholder="Name *" type="text" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <input name="phone" placeholder="Phone *" type="text" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <input name="email_address" placeholder="Email *" type="text" required />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <input name="contact_subject" placeholder="Subject *" type="text" />
                                            </div>
                                            <div className="col-12">
                                                <div className="contact2-textarea text-center">
                                                    <textarea placeholder="Message *" name="message" className="form-control2" required=""></textarea>
                                                </div>
                                                <div className="contact-btn">
                                                    <button className="check-btn sqr-btn1" type="submit">Send Message</button>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <p className="form-messege"></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> */}
            <div className="col-lg-8">
              <div className="card px-3 py-3 w-100 h-100">
                <div className="contact-info contact-info-2 pt-0">
                  <h2>contact us</h2>
                  {/* <p>{aboutUs}</p> */}
                  <ul>
                    <li>
                      <i className="fa fa-fax"></i> Address : {newAddress}
                    </li>
                    <li>
                      <i className="fa fa-envelope-o"></i> {email}
                    </li>
                    <li>
                      <i className="fa fa-phone"></i> {phone}
                    </li>
                  </ul>
                  {/* <div className="working-time">
                                        <h3>Working Hours</h3>
                                        <p>{working_hours}</p>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- contact wrapper area end --> */}
    </div>
  );
}
