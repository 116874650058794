import { GET_USER_TOKEN, LOGOUT_SESSION } from "../constants/Constants";

let initialState = {
  user: [],
};

const getUserTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case GET_USER_TOKEN:
      return {
        user: action.payload,
      };

      case LOGOUT_SESSION:
        return { ...initialState };


    default:
      break;
  }

  return state;
};

export default getUserTokenReducer;
