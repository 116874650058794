import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getDeliveryOrder } from "../../services/Order.service";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PATH } from "../../redux/constants/Constants";

export default function TrackOrder() {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const userData = useSelector((state) => state?.user?.user);
  const customerId = useSelector((state) => state?.user?.user?.token?.id);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  const {
    register,
    reset,
    setError,
    setValue,
    clearErrors,
    getValues,
    handleSubmit: handleSubmitOrderID,
    formState: { errors },
  } = useForm();

  const [yellow, setYellow] = useState(false);

  console.log(customerId)

  const handleOrderId = (item) => {
    console.log(item);

    if (!Array.isArray(userData) && userData) {
      customerId &&
        getDeliveryOrder(token, item.orderID, customerId).then((res) => {
          console.log(res);

          if (res?.data?.status === 201) {
            setError("orderID", {
              type: "manual",
              message: "We are still processing your Order",
            });
            setYellow(true);
          } else if (res?.data?.status === "Success") {
            console.log(res?.data);
            navigate(`/track-order-details`, {
              state: {
                orderDetails: res?.data,
              },
            });
          } else {
            setError("orderID", {
              type: "manual",
              message: "Invalid Order ID",
            });
            setYellow(false);
          }
        });
    } else {
      toast.warning("Please log in to continue", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
        containerId: "trackOrder",
      });

      setTimeout(() => {
        navigate(`/login`);
      }, 3000);
    }
  };

  const handleInput = (event) => {
    const value = event.target.value;
    if (value.length > 10) {
      event.target.value = value.slice(0, 10);
    }
  };

  return (
    <div
      className="d-flex justify-content-center p-5"
      style={{ minHeight: "800px" }}
    >
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="trackOrder"
      />

      <div className="col-lg-7 track-order-res p-5">
        <div class="card p-2" style={{ borderRadius: "10px" }}>
          <div class="card-body">
            <div className="d-flex">
              <img
                src={`${PATH}/assets/img/bamboosa/order-tracking1.png`}
                style={{ width: "9%", height: "9%" }}
                alt="order"
              />
              <h5 class="card-title shipment fw-bold mt-3 ms-4">
                Track status of your shipment
              </h5>
            </div>
            <hr style={{ height: "2px" }} />

            <form
              action="#"
              method="post"
              onSubmit={handleSubmitOrderID(handleOrderId)}
            >
              <h6 className="fw-bold mt-4">Order ID</h6>
              <div className="single-input-item mb-5 my-3">
                <input
                  type="number"
                  placeholder="Enter your Order ID"
                  {...register("orderID", {
                    required: "Enter Order ID",
                  })}
                  maxLength={10}
                  onInput={handleInput}
                />
                {yellow
                  ? errors.orderID && (
                      <p
                        className="error fw-bold mt-1 ms-2"
                        style={{ color: "#ffb900" }}
                      >
                        {errors.orderID.message}
                      </p>
                    )
                  : errors.orderID && (
                      <p className="error fw-bold mt-1 ms-2">
                        {errors.orderID.message}
                      </p>
                    )}
              </div>

              <div className="single-input-item">
                <button className="sqr-btn w-100" type="submit">
                  Check
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
