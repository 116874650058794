import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LeftSide from "./pages/LeftSide";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import OrderDetails from "./pages/OrderDetails";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "../../services/Cart.service";
import { userCartItems } from "../../redux/actions/Actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PATH } from "../../redux/constants/Constants";

export default function MyAccount() {
  const [activeTab, setActiveTab] = useState("dashboard");
  const location = useLocation();
  const cartId = useSelector(
    (state) => state.cart.cart.results && state.cart.cart.results[0].id
  );
  const dispatch = useDispatch();
  const token = useSelector((state) => state.status.restaurant.Token);

  useEffect(() => {
    window.scrollTo(0, 1);

    cartId &&
      getCartItems(token, cartId).then((res) => {
        dispatch(userCartItems(res?.data));

        console.log(res?.data);
      });

    window.scrollTo(0, 1);
    const path = location.pathname;
    console.log(path);
    setActiveTab(getTabFromPath(path));
  }, [location.pathname]);

  const getTabFromPath = (path) => {
    if (path.includes(`/my-account/orders`)) return "ordersTab";
    if (path.includes(`/my-account/profile`)) return "profileTab";
    if (path.includes(`/my-account/change-password`))
      return "changePasswordTab";
    if (path.includes(`/my-account/order-details`)) return "orderDetailsTab";
    return "";
  };
  console.log(activeTab);
  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
      />

      {/* <!-- my account wrapper start --> */}
      <div className="my-account-wrapper section-padding1 mx-3">
        <div className="container custom-container">
          <div className="row my-5">
            <div className="col-lg-12">
              {/* <!-- My Account Page Start --> */}
              <div className="myaccount-page-wrapper">
                {/* <!-- My Account Tab Menu Start --> */}
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    {/* col-md-4 */}
                    <LeftSide activeTab={activeTab} />
                  </div>
                  {/* <!-- My Account Tab Menu End --> */}

                  {/* <!-- My Account Tab Content Start --> */}
                  <div className="col-lg-9 col-md-9">
                    {/* col-md-8 */}

                    {activeTab === "" && <Dashboard activeTab={activeTab} />}
                    {activeTab === "ordersTab" && (
                      <Orders activeTab={activeTab} />
                    )}
                    {activeTab === "orderDetailsTab" && (
                      <OrderDetails activeTab={activeTab} />
                    )}
                    {activeTab === "profileTab" && (
                      <Profile activeTab={activeTab} />
                    )}
                    {activeTab === "changePasswordTab" && (
                      <ChangePassword activeTab={activeTab} />
                    )}
                  </div>
                  {/* <!-- My Account Tab Content End --> */}
                </div>
              </div>
              {/* <!-- My Account Page End --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- my account wrapper end --> */}
    </div>
  );
}
