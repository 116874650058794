import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../../redux/actions/Actions";
import { PATH } from "../../../redux/constants/Constants";

export default function LeftSide(props) {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const actTab = props.activeTab;
  console.log(actTab);
  const customer = useSelector((state) => state?.user?.user?.token);
  console.log(customer);

  const Logout = () => {
    dispatch(logoutSession());
    setTimeout(() => {
      navigate(`/login`);
    }, 1000);
  };

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <div className="">
      <div
        className="myaccount-tab-menu nav"
        role="tablist"
        aria-orientation="vertical"
      >
        <Link className={actTab === "" ? "active" : ""} to={`/my-account`}>
          <i className="fa fa-dashboard"></i>
          Dashboard
        </Link>
        <Link
          className={
            actTab === "ordersTab" || actTab === "orderDetailsTab"
              ? "active"
              : ""
          }
          to={`/my-account/orders`}
        >
          <i className="fa fa-cart-arrow-down"></i> Orders
        </Link>
        {customer?.username?.includes("guest_") ? null : (
          <Link
            className={actTab === "profileTab" ? "active" : ""}
            to={`/my-account/profile`}
          >
            <i className="fa fa-user"></i> Profile
          </Link>
        )}
        {customer?.username?.includes("guest_") ? null : (
          <Link
            className={actTab === "changePasswordTab" ? "active" : ""}
            to={`/my-account/change-password`}
          >
            <i className="fa fa-key"></i> Change Password
          </Link>
        )}

        <Link to="javascript:void(0)" onClick={() => Logout()}>
          <i className="fa fa-sign-out"></i> Logout
        </Link>
      </div>
    </div>
  );
}
