// preLoginCartReducer.js
import {
  ADD_ITEM_TO_PRELOGIN_CART,
  CLEAR_PRE_LOGIN_CART,
  LOGOUT_SESSION,
  REMOVE_PRE_LOGIN_CART_ITEM,
} from "../constants/Constants";

const initialState = {
  preLoginCart: [],
};

const preLoginCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_PRELOGIN_CART:
      return {
        ...state,
        preLoginCart: [...state.preLoginCart, action.payload],
      };

    case REMOVE_PRE_LOGIN_CART_ITEM:
      return {
        ...state,
        preLoginCart: state.preLoginCart.filter(
          (item) => item.color_id !== action.payload
        ),
      };

    case CLEAR_PRE_LOGIN_CART:
      return { ...initialState };

    case LOGOUT_SESSION:
      return { ...initialState };

    default:
      return state;
  }
};

export default preLoginCartReducer;
