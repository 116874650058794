// import React, { useEffect, useRef } from 'react';
// import $ from 'jquery';

// const Magnifier = ({ selector = 'img', magnification = 4, magnifierSize = '150px' }) => {
//   const magnifierRef = useRef(null);

//   useEffect(() => {
//     const magnifyImg = (ptr, magnification, magnifierSize) => {
//       let $pointer;
//       if (typeof ptr === 'string') {
//         $pointer = $(ptr);
//       } else if (typeof ptr === 'object') {
//         $pointer = ptr;
//       }

//       if (!$pointer.is('img')) {
//         alert('Object must be image.');
//         return false;
//       }

//       magnification = +magnification;

//       $pointer.hover(function () {
//         $(this).css('cursor', 'none');
//         $('.magnify').show();
//         const width = $(this).width();
//         const height = $(this).height();
//         const src = $(this).attr('src');
//         const imagePos = $(this).offset();
//         const image = $(this);

//         if (magnifierSize === undefined) {
//           magnifierSize = '150px';
//         }

//         $('.magnify').css({
//           'background-size': `${width * magnification}px ${height * magnification}px`,
//           'background-image': `url(${src})`,
//           'width': magnifierSize,
//           'height': magnifierSize
//         });

//         const magnifyOffset = +($('.magnify').width() / 2);
//         const rightSide = +(imagePos.left + $(this).width());
//         const bottomSide = +(imagePos.top + $(this).height());

//         $(document).mousemove(function (e) {
//           if (e.pageX < +(imagePos.left - magnifyOffset / 6) || e.pageX > +(rightSide + magnifyOffset / 6) || e.pageY < +(imagePos.top - magnifyOffset / 6) || e.pageY > +(bottomSide + magnifyOffset / 6)) {
//             $('.magnify').hide();
//             $(document).unbind('mousemove');
//           }
//           const backgroundPos = `${-(e.pageX - imagePos.left) * magnification + magnifyOffset}px ${-(e.pageY - imagePos.top) * magnification + magnifyOffset}px`;
//           $('.magnify').css({
//             'left': e.pageX - magnifyOffset,
//             'top': e.pageY - magnifyOffset,
//             'background-position': backgroundPos
//           });
//         });
//       }, function () { });
//     };

//     $('body').prepend('<div class="magnify"></div>');
//     magnifyImg(selector, magnification, magnifierSize);

//     return () => {
//       $('.magnify').remove();
//       $(document).unbind('mousemove');
//     };
//   }, [selector, magnification, magnifierSize]);

//   return <div ref={magnifierRef} />;
// };

// export default Magnifier;

import React, { useEffect, useRef } from 'react';
import $ from 'jquery';

const Magnifier = ({ containerSelector, magnification = 4, magnifierSize = '150px' }) => {
  const magnifierRef = useRef(null);

  useEffect(() => {
    const magnifyImg = (container, magnification, magnifierSize) => {
      const $container = $(container);
      const $pointer = $container.find('img');

      if (!$pointer.length) {
        alert('No images found in the specified container.');
        return false;
      }

      magnification = +magnification;

      $pointer.hover(function () {
        $(this).css('cursor', 'none');
        $('.magnify').show();
        const width = $(this).width();
        const height = $(this).height();
        const src = $(this).attr('src');
        const imagePos = $(this).offset();
        const image = $(this);

        if (magnifierSize === undefined) {
          magnifierSize = '150px';
        }

        $('.magnify').css({
          'background-size': `${width * magnification}px ${height * magnification}px`,
          'background-image': `url(${src})`,
          'width': magnifierSize,
          'height': magnifierSize
        });

        const magnifyOffset = +($('.magnify').width() / 2);
        const rightSide = +(imagePos.left + $(this).width());
        const bottomSide = +(imagePos.top + $(this).height());

        $(document).mousemove(function (e) {
          if (e.pageX < +(imagePos.left - magnifyOffset / 6) || e.pageX > +(rightSide + magnifyOffset / 6) || e.pageY < +(imagePos.top - magnifyOffset / 6) || e.pageY > +(bottomSide + magnifyOffset / 6)) {
            $('.magnify').hide();
            $(document).unbind('mousemove');
          }
          const backgroundPos = `${-(e.pageX - imagePos.left) * magnification + magnifyOffset}px ${-(e.pageY - imagePos.top) * magnification + magnifyOffset}px`;
          $('.magnify').css({
            'left': e.pageX - magnifyOffset,
            'top': e.pageY - magnifyOffset,
            'background-position': backgroundPos
          });
        });
      }, function () { });
    };

    $('body').prepend('<div class="magnify"></div>');
    magnifyImg(containerSelector, magnification, magnifierSize);

    return () => {
      $('.magnify').remove();
      $(document).unbind('mousemove');
    };
  }, [containerSelector, magnification, magnifierSize]);

  return <div ref={magnifierRef} />;
};

export default Magnifier;
