import { LOGOUT_SESSION, RESTAURANT_DETAILS } from "../constants/Constants";

let initialState = {
  restaurant: [],
};

const restaurantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case RESTAURANT_DETAILS:
      return {
        restaurant: action.payload,
      };

    default:
      break;
  }

  return state;
};

export default restaurantDetailsReducer;
