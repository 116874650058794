import { combineReducers } from "redux";
import restaurantDetailsReducer from "./RestaurantDetails.reducer";
import getUserTokenReducer from "./GetUserToken.reducer";
import userCartDetailsReducer from "./UserCartDetails.reducer";
import userCartItemsReducer from "./UserCartItems.reducer";
import userProductsReducer from "./UserProducts.reducer";
import rememberMeReducer from "./RememberMe.reducer";
import preLoginCartReducer from "./PreLoginCart.reducer";

const rootReducers = combineReducers({ status: restaurantDetailsReducer, user: getUserTokenReducer, cart: userCartDetailsReducer, cartItems: userCartItemsReducer, userProducts: userProductsReducer, rememberMe: rememberMeReducer, preLoginCart: preLoginCartReducer });

export default rootReducers;

