import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../../redux/actions/Actions";
import { getProfileDetails } from "../../../services/UserLogin.service";
import { PATH } from "../../../redux/constants/Constants";

export default function Dashboard(props) {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const actTab = props.activeTab;
  const customerId = useSelector((state) => state?.user?.user?.token?.id);
  const token = useSelector((state) => state.status.restaurant.Token);
  const [profileData, setProfileData] = useState();

  useEffect(() => {
    window.scrollTo(0, 1);

    fetchAPIs();
  }, []);

  const fetchAPIs = () => {
    customerId &&
      getProfileDetails(token, customerId).then((res) => {
        setProfileData(res?.data?.results?.[0]);
      });
  };
  console.log(profileData);

  const Logout = () => {
    dispatch(logoutSession());
    setTimeout(() => {
      navigate(`/login`);
    }, 1000);
  };

  return (
    <div style={{ minHeight: "800px" }}>
      <div className="tab-content" id="myaccountContent">
        {/* <!-- Single Tab Content Start --> */}
        <div
          className={`tab-pane fade ${actTab == "" ? "show active" : ""}`}
          id="dashboad"
          role="tabpanel"
          aria-labelledby="dashboad-tab"
        >
          <div className="myaccount-content">
            <h3>Dashboard</h3>
            <div className="welcome">
              <p>
                Hello,{" "}
                <strong className="text-capitalize">
                  {profileData?.customer?.first_name}{" "}
                  {profileData?.customer?.last_name}
                </strong>{" "}
                (If Not{" "}
                <strong className="text-capitalize">
                  {profileData?.customer?.first_name
                    ? profileData?.customer?.first_name
                    : "guest"}
                  !{" "}
                </strong>
                <Link
                  to="javascript:void(0)"
                  className="logout"
                  onClick={() => Logout()}
                >
                  {" "}
                  Logout
                </Link>
                )
              </p>
            </div>
            <p className="mb-0">
              From your account dashboard. you can easily check & view your
              recent orders, manage your shipping and billing addresses and edit
              your password and account details.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
