import { USER_CART_ITEMS, LOGOUT_SESSION } from "../constants/Constants";

let initialState = {
  cartItems: [],
};

const userCartItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case USER_CART_ITEMS:
      return {
        cartItems: action.payload,
      };

      case LOGOUT_SESSION:
        return { ...initialState };


    default:
      break;
  }

  return state;
};

export default userCartItemsReducer;
