import { USER_PRODUCTS } from "../constants/Constants";

let initialState = {
    products: [],
};

const userProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case USER_PRODUCTS:
      return {
        products: action.payload,
      };

    default:
      break;
  }

  return state;
};

export default userProductsReducer;
