import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Site from "./components/Site";
import Home from "./components/home/Home";
import Login from "./components/login-register/Login";
import Register from "./components/login-register/Register";
import MyAccount from "./components/my-account/MyAccount";
import TShirts from "./components/products/TShirts";
import ProductDetails from "./components/product-details/ProductDetails";
import ContactUs from "./components/contact-us/ContactUs";
import Cart from "./components/cart/Cart";
import Dashboard from "./components/my-account/pages/Dashboard";
import Orders from "./components/my-account/pages/Orders";
import OrderDetails from "./components/my-account/pages/OrderDetails";
import Profile from "./components/my-account/pages/Profile";
import ChangePassword from "./components/my-account/pages/ChangePassword";
import Checkout from "./components/checkout/Checkout";
import Search from "./components/products/Search";
import TrackOrder from "./components/track-order/TrackOrder";
import TrackOrderDetails from "./components/track-order/TrackOrderDetails";
import AboutUs from "./components/contact-us/AboutUs";
import ReturnPolicy from "./components/contact-us/ReturnPolicy";
import TermsAndConditions from "./components/contact-us/TermsAndConditions";
import PrivacyPolicy from "./components/contact-us/PrivacyPolicy";
import ShippingPolicy from "./components/contact-us/ShippingPolicy";
import { PATH } from "./redux/constants/Constants";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path={`/`} element={<Site />}>
        <Route path={`/`} element={<Home />} />
        <Route path={`'/login'`} element={<Login />} />
        <Route path={`/register`} element={<Register />} />
        <Route path='/products' element={<TShirts />} />
        <Route path='/product-details' element={<ProductDetails />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/search' element={<Search />} />

        <Route path='/my-account' element={<MyAccount />} >
          <Route path='/my-account' element={<Dashboard />} />
          <Route path='/my-account/orders' element={<Orders />} />
          <Route path='/my-account/order-details' element={<OrderDetails />} />
          <Route path='/my-account/profile' element={<Profile /> } />
          <Route path='/my-account/change-password' element={<ChangePassword /> } />
        </Route>

        <Route path='/track-order' element={<TrackOrder /> } />
        <Route path='/track-order-details' element={<TrackOrderDetails /> } />
        <Route path='/about-us' element={<AboutUs /> } />
        <Route path='/return-policy' element={<ReturnPolicy /> } />
        <Route path='/terms-and-conditions' element={<TermsAndConditions /> } />
        <Route path='/privacy-policy' element={<PrivacyPolicy /> } />
        <Route path='/shipping-policy' element={<ShippingPolicy /> } />


      </Route> */}
        <Route path={`/`} element={<Site />}>
          <Route path={`/`} element={<Home />} />
          <Route path={`/login`} element={<Login />} />
          <Route path={`/register`} element={<Register />} />
          <Route path={`/products`} element={<TShirts />} />
          <Route path={`/product-details`} element={<ProductDetails />} />
          <Route path={`/contact-us`} element={<ContactUs />} />
          <Route path={`/cart`} element={<Cart />} />
          <Route path={`/checkout`} element={<Checkout />} />
          <Route path={`/search`} element={<Search />} />

          <Route path={`/my-account`} element={<MyAccount />}>
            <Route path={`/my-account`} element={<Dashboard />} />
            <Route path={`/my-account/orders`} element={<Orders />} />
            <Route
              path={`/my-account/order-details`}
              element={<OrderDetails />}
            />
            <Route path={`/my-account/profile`} element={<Profile />} />
            <Route
              path={`/my-account/change-password`}
              element={<ChangePassword />}
            />
          </Route>

          <Route path={`/track-order`} element={<TrackOrder />} />
          <Route
            path={`/track-order-details`}
            element={<TrackOrderDetails />}
          />
          <Route path={`/about-us`} element={<AboutUs />} />
          <Route path={`/return-policy`} element={<ReturnPolicy />} />
          <Route
            path={`/terms-and-conditions`}
            element={<TermsAndConditions />}
          />
          <Route path={`/privacy-policy`} element={<PrivacyPolicy />} />
          <Route path={`/shipping-policy`} element={<ShippingPolicy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
